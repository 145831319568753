// const Network = require("@iota/identity-wasm/web");

// const url = "http://172.16.1.132:1904";

// const url = "http://172.16.1.172:1904";
// export const socketURL = "ws://172.16.1.172:1904";

// export const socketURL = "wss://172.16.1.172:1904";

export const socketURL = "ws://node-iot.mobiloitte.com";
const url = "https://node-iot.mobiloitte.com";

//http://182.72.203.250:1904/api-docs/
// export const socketURL = "ws://182.72.203.250:1904";
// const url = "http://182.72.203.250:1904";

const user = `${url}/api/v1/user`;
const device = `${url}/api/v1/device`;
const admin = `${url}/api/v1/admin`;
const kyc = `${url}/api/v1/kyc`;
const doc = `${url}/api/v1/doc`;
const ApiConfig = {
  //user
  signUp1: `${user}/signUp`,
  userLogin: `${user}/userLogin`,
  forgotPassword: `${user}/forgotPassword`,
  otpVerify: `${user}/verifyOTP`,
  resetPassword: `${user}/resetPassword`,
  resendOtp: `${user}/resendOtp`,
  getProfile: `${user}/getProfile`,
  editProfile: `${user}/editProfile`, //user/editProfile
  holderAddVerification: `${user}/holderAddVerification`,
  holderCreateCrediantial: `${user}/holderCreateCrediantial`,
  issuerListUser: `${user}/issuerList`,
  unsignedHolderlist: `${user}/unsignedHolderlist`,
  unsignedHolderView: `${user}/unsignedHolderView/`,
  issuerSignedVcForHolder: `${user}/issuerSignedVcForHolder/`,

  signedVpForHolder: `${user}/signedVpForHolder`,
  allSharedWalletByHolder_manuList: `${user}/allSharedWalletByHolder_manuList`,
  viewSharedwallet: `${user}/viewSharedwallet`,
  verifierList: `${user}/verifierList`,
  shareCrediantialToVerifier: `${user}/shareCrediantialToVerifier`,

  shareCrediantialToVerifier: `${user}/shareCrediantialToVerifier`,
  holderRequestFordevice: `${user}/holderRequestFordevice`,
  manufacturerAddVerification: `${user}/manufacturerAddVerification/`,
  ipfsUpload: `${user}/ipfsUpload/`, //user/ipfsUpload
  acceptWalletCrediantial: `${user}/acceptWalletCrediantial/`, //user/ipfsUpload
  rejectWalletCrediantial: `${user}/rejectWalletCrediantial/`, //user/ipfsUpload
  //devices
  listdevice: `${device}/listdevice`,
  addDevice: `${device}/addDevice`,
  details: `${device}/details`,
  viewDevice: `${device}/viewDevice/`,
  editDevice: `${device}/editDevice`,
  addIOTDevice: `${device}/addIOTDevice`,
  deviceListForParticular: `${device}/deviceListForParticular`,
  deviceIOTListForParticular: `${device}/deviceIOTListForParticular`,
  deviceIOTListForManufacturer: `${device}/deviceIOTListForManufacturer`,
  deviceListForManufacturer: `${device}/deviceListForManufacturer`,
  CreateDeviceCrediantial: `${device}/CreateDeviceCrediantial/`, //device/issuerCreateDeviceCrediantial/{_id}
  rejectDeviceCrediantialByManufacturer: `${device}/rejectDeviceCrediantialByManufacturer`,
  //admin
  manufacturerList: `${admin}/manufacturerListWithApprove`,
  userList: `${admin}/userList`,
  dashboard: `${admin}/dashboard`,
  viewUser: `${admin}/viewUser/`,
  issuerList: `${admin}/issuerList`,
  manufacturerListWithoutApproved: `${admin}/manufacturersList`,
  viewIssuer: `${admin}/viewIssuer/`,
  checkSignVp: `${admin}/checkSignVp/`,
  checkSignVc: `${admin}/checkSignVc/`,
  manufacturerVerification: `${admin}/manufacturerVerification/`, ///user/manufacturerAddVerification

  // approveIssuerRequest: `${admin}/approveIssuerRequest/`,
  deviceRequestList: `${admin}/deviceRequestList`, //admin/deviceRequestList
  deviceRequestview: `${admin}/deviceRequestview/`, //admin/deviceRequestview/{_id}
  approveIssuerRequest: `${admin}/approveRequest/`,
  rejectRequestForDevice: `${admin}/rejectRequestForDevice/`,
  rejectRequest: `${admin}/rejectRequest/`, //admin/rejectRequest/{_id}
  approveRequestForDevice: `${admin}/approveRequestForDevice/`, //admin/approveRequestForDevice/{_id}
  //KYC
  addKyc: `${kyc}/addKyc`,
  kycList: `${kyc}/kycList`,
  viewKyc: `${kyc}/viewKyc/`,
  verifyKyc: `${kyc}/verifyKyc/`,

  // doccument
  createCrediantialForPassport: `${doc}/createCrediantialForPassport`,
  listCrediantialForPassport: `${doc}/listCrediantialForPassport`,
  createCrediantialForLicense: `${doc}/createCrediantialForLicense`,
  listCrediantialForLicense: `${doc}/listCrediantialForLicense`,
  viewPassport: `${doc}/viewPassport/`,
  issuerPassportCreateCrediantial: `${doc}/issuerPassportCreateCrediantial/`,
  viewLicense: `${doc}/viewLicense/`,
  issuerLicenseCreateCrediantial: `${doc}/issuerLicenseCreateCrediantial/`,
  // issuerRejectCrediantailsForLicense: `${doc}/issuerRejectCrediantailsForLicense/`,
  issuerRejectCrediantailsForLicense: `${doc}/issuerRejectCrediantailsForLicense/`,
  issuerRejectCrediantailsForPassport: `${doc}/issuerRejectCrediantailsForPassport/`,
  getPassportDetails: `${doc}/getPassportDetails`,
  getLicenseDetails: `${doc}/getLicenseDetails`,
  signedVpLicense: `${doc}/signedVpLicense/`,
  signedVpPassport: `${doc}/signedVpPassport/`,

  addIOTDevice: `${device}/addIOTDevice`,
  claimOnDevice: `${device}/claimOnDevice`,
};

// const identity = require("@iota/identity-wasm/node");

// const MAINNET = Network.mainnet();
// const MAINNET = Network.mainnet;

// // / @type {{network: Network, defaultNodeURL: string, explorerURL: string}} /
// const CLIENT_CONFIG = {
//   network: MAINNET,
//   defaultNodeURL: MAINNET.defaultNodeURL,
//   explorerURL: MAINNET.explorerURL,
// };

// exports.CLIENT_CONFIG = CLIENT_CONFIG;

// export const DEVNET = false;

// export const API_URL = "http://localhost:4000";

// export const IOTA_NODE_URL = "https://chrysalis-nodes.iota.org";

// export const IOTA_PERMANODE_URL = "https://chrysalis-chronicle.iota.org/api/mainnet/";

// export const RANDOM_USER_DATA_API_URL = "https://randomuser.me/api/?nat=us,au,ca,ie,gb";

// export const TUTORIAL_URL = "/docs/in-app-tutorial.md";

// export const IDENTITY_WASM_PATH = "/wasm/identity_wasm_bg.wasm";

// export const BACK_BUTTON_EXIT_GRACE_PERIOD = 2000; // 2s is same duration as "short" Toast

// export const IOTA_IDENTITY_RESOLVER = "https://explorer.iota.org/mainnet/identity-resolver";

// export const CREDENTIAL_EXPIRY_DAYS = 30;

export default ApiConfig;
