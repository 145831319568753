import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    exact: true,
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },
  {
    exact: true,
    path: "/users",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Userlist")),
  },
  {
    exact: true,
    path: "/unsigned-holders-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UnSignedHoldersList")
    ),
  },

  {
    exact: true,
    path: "/passport-vc-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Varification/PassportList")
    ),
  },
  {
    exact: true,
    path: "/view-vc-details",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Varification/ViewVCdetails")
    ),
  },
  {
    exact: true,
    path: "/licence-vc-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Varification/Licance")
    ),
  },
  {
    exact: true,
    path: "/issuers-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Issuers-list")),
  },
  {
    exact: true,
    path: "/manufacturer-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/ManufacturalList")),
  },
  {
    exact: true,
    path: "/edit-profile",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/EditProfile")),
  },
  {
    exact: true,
    path: "/kyc-pending",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Kycpending/Kycpending")),
  },
  {
    exact: true,
    path: "/Terms-Condition",

    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Terms&Condition/Terms")),
  },
  {
    exact: true,
    path: "/Privacy-Policy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Privacy/privacy")),
  },
  {
    exact: true,
    path: "/view-profile",
    guard: true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Kycpending/Seeprofile")),
  },

  {
    exact: true,
    path: "/profile",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ViewHolderProfile")
    ),
  },

  {
    exact: true,
    path: "/view-users",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/ViewUsers")),
  },
  {
    exact: true,
    path: "/search",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Search/Search")),
  },
  {
    exact: true,
    path: "/view-issuer",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/ViewIssuer")),
  },
  {
    exact: true,
    path: "/login",

    layout: LoginLayout,

    component: lazy(() => import("src/views/auth/LogIn")),
  },
  {
    exact: true,
    path: "/signup",

    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/signup/signup")),
  },
  {
    exact: true,
    path: "/otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/Otp/ForgotPaasOTPVer")),
  },
  {
    exact: true,
    path: "/forget-password",
    // guard:true,
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/instrauctions",
    // guard: true,
    component: lazy(() => import("src/views/auth/forget-password-link/index")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    // guard:true,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },
  {
    exact: true,
    path: "/kycpending",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Kycpending")),
  },
  {
    exact: true,
    path: "/add-devices",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/AddDevices")),
  },
  {
    exact: true,
    path: "/add-iot-devices",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/AutomaticDeviceFetched")),
  },
  {
    exact: true,
    path: "/devicelist",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Devicelist")),
  },
  {
    exact: true,
    path: "/my-devicelist",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Devicelist/Details/MyDeviceList")
    ),
  },
  {
    exact: true,
    path: "/my-iot-devicelist",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Devicelist/Details/MyIotDeviceList")
    ),
  },
  {
    exact: true,
    path: "/manufacturer-devicelist",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Devicelist/Details/ManufaturerDeviceList")
    ),
  },
  {
    exact: true,
    path: "/iot-devicelist",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Devicelist/Details/ManufacturerIotDeviceList")
    ),
  },

  {
    exact: true,
    path: "/device-request-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Devicelist/Details/DeviceRequestList")
    ),
  },
  {
    exact: true,
    path: "/view-request-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Devicelist/Details/ViewRequestList")
    ),
  },
  {
    exact: true,
    path: "/details",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Devicelist/Details")),
  },
  ///verify-credentials
  {
    exact: true,
    path: "/verify-credentials",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/KYC/KycList")),
  },
  {
    exact: true,
    path: "/add-kyc",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/KYC")),
  },

  {
    exact: true,
    path: "/add-verification",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/KYC")),
  },
  {
    exact: true,
    path: "/vc-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/VERIFIRE/Verifire")
    ),
  },
  {
    exact: true,
    path: "/select-document",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Varification/Varification")
    ),
  },
  {
    exact: true,
    path: "/view-doc-details",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/VERIFIRE/ViewDocument")
    ),
  },
  {
    exact: true,
    path: "/view-document-details",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/PassportLicense/index")),
  },
  {
    exact: true,
    path: "/view-license-details",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/PassportLicense/LicenseDetails")
    ),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
