/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import Logo from "src/component/Logo";
import { AuthContext } from "src/context/Auth";
import {
  FaTachometerAlt,
  FaUniversity,
  FaWallet,
  FaClipboardCheck,
  FaMoneyBillAlt,
  FaPhoneSquareAlt,
  FaGooglePlusG,
} from "react-icons/fa";
import { HiDocumentSearch } from "react-icons/hi";
import { AiOutlineHome } from "react-icons/ai";
import { MdDashboard } from "react-icons/md";
import { IoIosPeople } from "react-icons/io";
import NavItem from "./NavItem";
import PeopleIcon from "@material-ui/icons/People";
import PersonIcon from "@material-ui/icons/Person";
import DevicesOtherIcon from "@material-ui/icons/DevicesOther";
const sections = [
  {
    items: [
      {
        title: "Home",
        icon: AiOutlineHome,
        href: "/",
      },
      {
        title: "Dashboard",
        icon: MdDashboard,
        href: "/dashboard",
      },

      {
        title: "Search",
        icon: FaClipboardCheck,
        href: "/search",
      },

      {
        title: "Add Devices",
        icon: FaGooglePlusG,
        href: "/add-devices",
      },
      {
        title: "Add IOT Devices",
        icon: FaGooglePlusG,
        href: "/add-iot-devices",
      },
      {
        title: "Issuers List",
        icon: PeopleIcon,
        href: "/issuers-list",
      },
      {
        title: "Manufacturer List",
        icon: PeopleIcon,
        href: "/manufacturer-list",
      },
      {
        title: "User List",
        icon: IoIosPeople,
        href: "/users",
      },
      // {
      //   title: "KYC Pending",
      //   icon: PersonIcon,
      //   href: "/kycpending",
      // },
      {
        title: "Device List",
        icon: DevicesOtherIcon,
        href: "/devicelist",
      },
      // {
      //   title: "My Device List",
      //   icon: DevicesOtherIcon,
      //   href: "/my-devicelist",
      // },
      {
        title: "My IOT Device List",
        icon: DevicesOtherIcon,
        href: "/my-iot-devicelist",
      },
      // {
      //   title: " Device Request List",
      //   icon: DevicesOtherIcon,
      //   href: "/device-request-list",
      // },
    ],
  },
];
const sectionsForIssuer = [
  {
    items: [
      {
        title: "Home",
        icon: AiOutlineHome,
        href: "/",
      },
      {
        title: "Dashboard",
        icon: MdDashboard,
        href: "/dashboard",
      },
      {
        title: "Search",
        icon: FaClipboardCheck,
        href: "/search",
      },
      {
        title: "Passport VC List",
        icon: PeopleIcon,
        href: "/passport-vc-list",
      },

      {
        title: "Licence VC List",
        icon: PeopleIcon,
        href: "/licence-vc-list",
      },
      {
        title: "VC List",
        icon: PeopleIcon,
        href: "/unsigned-holders-list",
      }, //unsigned-holders-list
      // {
      //   title: "VC Pending",
      //   icon: PersonIcon,
      //   href: "/kycpending",
      // },
    ],
  },
];

const sectionsForHolder = [
  {
    items: [
      {
        title: "Home",
        icon: AiOutlineHome,
        href: "/",
      },
      {
        title: "Dashboard",
        icon: MdDashboard,
        href: "/dashboard",
      },
      {
        title: "Search",
        icon: FaClipboardCheck,
        href: "/search",
      },
      {
        title: "Document",
        icon: HiDocumentSearch,
        href: "/",

        items: [
          {
            title: "Passport Details",
            icon: FaGooglePlusG,
            href: "/view-document-details",
          },
          {
            title: "License Details",
            icon: FaGooglePlusG,
            href: "/view-license-details",
          },
        ],
      },

      // {
      //   title: "Passport Details",
      //   icon: FaGooglePlusG,
      //   href: "/view-document-details",
      // },

      // {
      //   title: "License Details",
      //   icon: FaGooglePlusG,
      //   href: "/view-license-details",
      // },
      {
        title: "Add Devices",
        icon: FaGooglePlusG,
        href: "/add-devices",
      },
      {
        title: "Add IOT Devices",
        icon: FaGooglePlusG,
        href: "/add-iot-devices",
      },
      {
        title: "Device List",
        icon: DevicesOtherIcon,
        href: "/devicelist",
      },

      // {
      //   title: "My Device List",
      //   icon: DevicesOtherIcon,
      //   href: "/my-devicelist",
      // },

      {
        title: "My IOT Device List",
        icon: DevicesOtherIcon,
        href: "/my-iot-devicelist",
      },
      // {
      //   title: " Device Request List",
      //   icon: DevicesOtherIcon,
      //   href: "/device-request-list",
      // },
    ],
  },
];
const sectionsForVerifire = [
  {
    items: [
      {
        title: "Home",
        icon: AiOutlineHome,
        href: "/",
      },
      {
        title: "Dashboard",
        icon: MdDashboard,
        href: "/dashboard",
      },

      {
        title: "Vc Lists",
        icon: DevicesOtherIcon,
        href: "/vc-list",
      },
      // {
      //   title: "IOT Device List",
      //   icon: DevicesOtherIcon,
      //   href: "iot-devicelist",
      // },
    ],
  },
];
const sectionsForManufacture = [
  {
    items: [
      {
        title: "Home",
        icon: AiOutlineHome,
        href: "/",
      },
      {
        title: "Dashboard",
        icon: MdDashboard,
        href: "/dashboard",
      },

      {
        title: "Search",
        icon: FaClipboardCheck,
        href: "/search",
      },
      {
        title: "Device List",
        icon: DevicesOtherIcon,
        href: "/manufacturer-devicelist",
      },
      {
        title: "IOT Device List",
        icon: DevicesOtherIcon,
        href: "iot-devicelist",
      },
    ],
  },
];
const sectionsBelow = [
  {
    items: [
      {
        title: "Terms & Condition",
        //icon: PieChartIcon,
        href: "/Terms-Condition",
      },
      {
        title: "Privacy Policy",
        //icon: PieChartIcon,
        href: "Privacy-Policy",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: "white",
  },
  desktopDrawer: {
    width: 256,
    top: 0,
    height: "100%",
    background: "white",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const auth = React.useContext(AuthContext);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const logOutHandler = () => {
    window.sessionStorage.removeItem("token");
    window.sessionStorage.removeItem("email");
    window.sessionStorage.removeItem("firstName");
    window.sessionStorage.removeItem("lastName");
    window.sessionStorage.removeItem("userType");
    history.push("/");
  };
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Hidden mdDown>
        <Box padding={0}>
          <Logo
            width="180"
            style={{
              paddingTop: "30px",
              paddingLeft: "15px",
              cursor: "pointer",
            }}
          />
        </Box>
      </Hidden>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        {console.log("usertype", auth.userData)}

        {auth.userData?.userType === "ADMIN" && (
          <Box pt={4}>
            {sections.map((section, i) => (
              <List
                key={`menu${i}`}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname,
                })}
              </List>
            ))}
          </Box>
        )}
        {auth.userData?.userType === "ISSUER" && (
          <Box py={4}>
            {sectionsForIssuer.map((section, i) => (
              <List
                key={`menu${i}`}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname,
                })}
              </List>
            ))}
          </Box>
        )}
        {/* sectionsForManufacture */}
        {auth.userData?.userType === "MANUFATURER" && (
          <Box py={4}>
            {sectionsForManufacture.map((section, i) => (
              <List
                key={`menu${i}`}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname,
                })}
              </List>
            ))}
          </Box>
        )}
        {auth.userData?.userType === "VERIFIRE" && (
          <Box py={4}>
            {sectionsForVerifire.map((section, i) => (
              <List
                key={`menu${i}`}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname,
                })}
              </List>
            ))}
          </Box>
        )}
        {auth.userData?.userType === "HOLDER" && (
          <Box py={4}>
            {sectionsForHolder.map((section, i) => (
              <List
                key={`menu${i}`}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname,
                })}
              </List>
            ))}
          </Box>
        )}
        <Box className="side_nev_Bottom">
          <Box className="">
            <Button
              fullWidth
              // key={i}
              style={{
                justifyContent: "start",
                paddingLeft: 33,
                borderRadius: 0,
                textTransform: "capitalize",
              }}
              onClick={() => logOutHandler()}
            >
              Logout
            </Button>
          </Box>
          {sectionsBelow.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {section.items.map((itemList, i) => {
                return (
                  <Button
                    fullWidth
                    key={i}
                    style={{
                      justifyContent: "start",
                      paddingLeft: 25,
                      borderRadius: 0,
                      textTransform: "capitalize",
                    }}
                    onClick={() => history.push(itemList.href)}
                  >
                    {itemList.title}
                  </Button>
                );
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
