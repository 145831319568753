export const NetworkContextName = "IOTA NETWORK";
// export const ACTIVE_NETWORK = 1074;
// export const default_RPC_URL = "https://evm.wasp.sc.iota.org/";
// export const explorerURL = "https://explorer.wasp.sc.iota.org/";

// BSCnetwork
export const ACTIVE_NETWORK = 97;
export const default_RPC_URL =
  "https://data-seed-prebsc-1-s3.binance.org:8545/";
export const explorerURL = "https://testnet.bscscan.com";

export const ManufaturerContract = "0x0b63f8bA65D39B6e2c8561Df00EF1B8Be3b49363";
export const IssuerContract = "0x58aA21b812133CFde4ED4aE7c2E9C69f7D1B95De"; //distributorContract
export const HolderContract = "0x0090C58F320C50085bA02F27336b6d29E0B1dF48";
export const PersonalIdContract = "0xaB0339a6165A320F4bB370a11F9492d3C1E4580b";
export const FeedBackContract = "0x8ABb7FBB146FE6e66b4C48b95270Cd036bE47096";

export const NetworkDetails = {
  chainId: "0x61",
  chainName: "IOTA Network",
  nativeCurrency: {
    name: "IOTA Network",
    symbol: "TEST",
    decimals: 18,
  },
  rpcUrls: [default_RPC_URL],
  blockExplorerUrls: [explorerURL],
};

// export const NewMANUFATURERDATA = "";
