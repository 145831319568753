import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import ApiConfig, { socketURL } from "src/config/APICongig";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
import { injected } from "../connectors";
import { NetworkDetails, ACTIVE_NETWORK } from "src/constants/index";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};
function checkLogin() {
  const accessToken = window.sessionStorage.getItem("token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});
  const { activate, deactivate, account, library, chainId } = useWeb3React();

  // const [userData, setUserDate] = useState();

  const connectToWallet = () => {
    try {
      activate(injected, undefined, true).catch((error) => {
        if (error) {
          console.log("ERROR", error);
          const errorMSG = error.message; //+ ' Please install Metamask';
          toast.warn(errorMSG);
          // alert(errorMSG);
          activate(injected);
        }
      });
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  useEffect(() => {
    if (account) {
      if (chainId != ACTIVE_NETWORK) {
        swichNetworkHandler(ACTIVE_NETWORK);
      }
    }
  }, [chainId, account]);

  const swichNetworkHandler = async (id) => {
    if (window.ethereum) {
      console.log(id);
      console.log(window.ethereum);
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x" + parseFloat(id).toString(16) }],
        });
      } catch (error) {
        console.log("ERROR", error);
        // toast.warn(error.message);
        if (error.code === 4902) {
          addNetworkHandler(id);
        }
      }
    }
  };

  const addNetworkHandler = async (id) => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [NetworkDetails],
      });
    } catch (error) {
      console.log("ERROR", error);
      toast.warn(error.message);
    }
  };

  const viewProfileHandler = async () => {
    try {
      const res = await axios.get(ApiConfig.getProfile, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      if (res.data.statusCode === 200) {
        setUserData(res?.data?.result);
      } else {
        setUserData();
        setIsLogin(false);
      }
      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    if (isLogin) {
      viewProfileHandler();
      // getExchangeBalanceHandler();
      // exchangeListHandler();
    } else {
      // setIsLoading(false);
    }
  }, [isLogin]);
  let data = {
    userLoggedIn: isLogin,
    userData,
    connectWallet: (data) => connectToWallet(data),
    swichNetwork: (data) => swichNetworkHandler(data),
    viewProfileHandler: () => viewProfileHandler(),
    setUserData: (data) => setUserData(data),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
